<template>
    <section :id="id" :class="{ ...basicClassName, ...className }" :style="{ '--form-bg-height': `${sectionHeight}px` }">
        <s-form-header-sem-ebook v-if="content.header" :content="content.header" />

        <a-breadcrumbs
            v-if="content.breadcrumbs"
            sublinks
            :theme="isDarkBackground ? 'dark' : 'light'"
            :links="content.breadcrumbs"
        />

        <div class="a-container">
            <div :class="classNameFormWrapper">
                <s-form-info v-if="isVisibleInfo('before')" :content="content" :blocks="formInfoContent('before')" />
                <s-form-container :form="formData" :use-phone-input="content.usePhoneInput" @resize="onResize" />
            </div>
            <s-form-info v-if="isVisibleInfo('after')" :content="content" :blocks="formInfoContent('after')" />
            <s-info-content-awards v-if="isVisibleAwards" :content="content" />
            <s-form-footer v-if="content.footer" :content="{ ...content.footer, isEbook: content.isEbook }" />
        </div>
    </section>
</template>

<script>
import { isEmpty, merge } from 'lodash';
import { mapState } from 'vuex';
import ABreadcrumbs from '@core/components/breadcrumbs/breadcrumbs.vue';
import breakpoint from '@core/mixins/breakpoint.js';
import contentMixin from '@core/mixins/content.js';
import phoneInput from '@core/mixins/phone-input.js';
import styleMixin from '@core/mixins/style.js';
import SFormFooter from './components/footer.vue';
import SFormContainer from './components/form/form.vue';
import SFormHeaderSemEbook from './components/header-sem-ebook.vue';
import SInfoContentAwards from './components/info/info-content-awards.vue';
import SFormInfo from './components/info/info.vue';

export default {
    name: 'STrialForm',

    components: {
        ABreadcrumbs,
        SFormFooter,
        SFormContainer,
        SFormHeaderSemEbook,
        SInfoContentAwards,
        SFormInfo,
    },

    mixins: [contentMixin, styleMixin, breakpoint, phoneInput],

    inheritAttrs: false,

    props: {
        content: {
            type: Object,
            default: () => ({}),
        },

        blocks: {
            type: Array,
            default: null,
        },

        form: {
            type: Object,
            default: null,
        },

        isSingle: {
            type: Boolean,
            default: false,
        },
    },

    data() {
        return {
            sectionHeight: 0,
        };
    },

    computed: {
        ...mapState({
            slices: (state) => state.slices.items || {},
        }),

        className() {
            return {
                's-form': this.form,
                's-form-single': this.isSingle,
                's-form-beta': this.form?.isBeta,
                's-form-sem-ebook': this.content.isEbook,
                's-form-sticky': this.form?.settings?.isSticky,
                's-form-has-background': this.form?.settings?.hasBackground,
                's-form-has-padding-top': this.form?.settings?.hasPaddingTop,
                's-form-border-radius-16': this.form?.settings?.increaseBorderRadius,
                's-form-dark-page': this.isDarkBackground && this.form?.settings?.isSingleSlice,
                [`s-form-version-${this.content.version}`]: this.content?.version,
            };
        },

        classNameFormWrapper() {
            return {
                's-form__wrapper': this.form,
                's-form__wrapper-blocks': this.blocks,
                [`s-form__wrapper--version-${this.formData.version}`]: this.formData.version,
            };
        },

        locale() {
            return this.$route.params.locale;
        },

        isDarkBackground() {
            return this.background === 'dark-blue';
        },

        presetName() {
            return this.form.presetName || '';
        },

        presetForm() {
            return this.slices[this.presetName]?.form || {};
        },

        formData() {
            return {
                sliceID: this.id,
                version: this.content.version || null,
                isEbook: this.content.isEbook || null,
                ...merge(this.presetForm, this.form),
            };
        },

        isVisibleAwards() {
            return this.isMobile && this.content.awards;
        },
    },

    mounted() {
        window.addEventListener('resize', this.onResize);
    },

    beforeMount() {
        window.addEventListener('pageshow', this.reload);
    },

    beforeDestroy() {
        window.removeEventListener('pageshow', this.reload);
        window.removeEventListener('resize', this.onResize);
    },

    methods: {
        isVisibleInfo(position) {
            const hasContent = !isEmpty(this.content);
            if (position === 'after') {
                if (!this.isMobile) return false;
                if (this.isMobile && hasContent) return false;
            }
            if (position === 'before' && this.isMobile && this.content.isEbook) return false;
            return hasContent || !isEmpty(this.blocks);
        },

        reload(event) {
            if (event && event.persisted) {
                document.getElementsByTagName('html')[0].style.display = 'none';
                window.location.reload();
            }
        },

        hasBlockName(name) {
            return ['logo', 'title', 'subtitle'].includes(name);
        },

        formInfoContent(position) {
            if (!this.blocks) return null;
            if (position !== 'before') return this.blocks.filter((item) => !this.hasBlockName(item.name));
            if (this.isMobile) return this.blocks.filter((item) => this.hasBlockName(item.name));
            return this.blocks;
        },

        onResize() {
            const heightPage = this.$root.$el.offsetHeight;
            const heightSlice = document.querySelector('.s-form')?.offsetHeight;

            if (this.form?.settings?.isSingleSlice) {
                this.sectionHeight = heightPage > heightSlice ? heightPage : heightSlice;
                return;
            }

            this.sectionHeight = heightSlice || heightPage;
        },
    },

    serverPrefetch() {
        if (this.presetName) {
            this.$store.dispatch('slices/getSyncedData', { slice: this.presetName, locale: this.locale });
        }
        this.$store.dispatch('slices/getSyncedData', { slice: 's-data-centers', locale: this.locale });
        this.$store.dispatch('locales/getLocaleData', { locale: this.locale });
        this.$store.dispatch('countries/getCountries');
    },
};
</script>

<style lang="postcss" scoped>
.s-form {
    display: flex;
    padding: 32px 0;
    flex-flow: column;
    align-items: center;

    @media (--viewport-desktop) {
        padding: 64px 0;
    }

    .a-container {
        width: 100%;
    }

    &__wrapper {
        max-width: 648px;
    }

    &-single {
        padding: 120px 0 64px;
    }

    &-sem-ebook {
        padding: 0;
    }

    .a-breadcrumbs {
        margin-top: 24px;
    }
}

.s-form-has-background {
    &:deep(.s-form-container) {
        &:before {
            @media (--viewport-desktop) {
                content: '';
                left: 10%;
                width: 80%;
                top: -64px;
                position: absolute;
                height: var(--form-bg-height);
                background: var(--av-brand-secondary-accent);
            }
        }
    }

    &.s-form-has-padding-top {
        &:deep(.s-form-container) {
            &:before {
                @media (--viewport-desktop) {
                    top: -128px;
                }
            }
        }
    }
}

.s-form-sticky {
    &:deep(.s-form-container) {
        @media (--viewport-desktop) {
            top: 64px;
            position: sticky;
            align-self: flex-start;
        }
    }
}

.s-form-border-radius-16 {
    &:deep(.s-form-container) {
        .s-form-error,
        .s-form-login,
        .s-form-change,
        .s-form-upsell,
        .s-form-success,
        .s-form-registration,
        .s-form-support-confirmation,
        .notification {
            border-radius: 16px;
            border: 2px solid var(--av-brand-secondary-light);
        }

        .s-form-container__loading,
        .s-form-registration__loading {
            border-radius: 16px;
        }
    }
}

.s-form-has-padding-top {
    &:deep(.s-form-container) {
        @media (--viewport-tablet) {
            margin-top: 64px;
        }
    }
}

.s-form-dark-page {
    &:before {
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        position: fixed;
        background: var(--av-nav-primary);
    }
}

.s-form.s-form-version-42019 {
    padding: 88px 0 104px;

    .a-container {
        margin-top: 24px;
    }

    .s-info {
        padding: 0;
        margin-inline-end: 16px;

        @media (--viewport-tablet) {
            width: 50%;
        }

        @media (--viewport-desktop) {
            max-width: 471px;
        }

        @media (--viewport-desktop-wide) {
            margin-inline-end: 113px;
        }

        @media (--viewport-desktop-large) {
            max-width: 537px;
            margin-inline-end: 127px;
        }
    }

    .s-form__wrapper {
        max-width: 100%;

        @media (--viewport-tablet) {
            display: flex;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }

    .s-form-registration {
        &__subtitle {
            @mixin paragraph;
        }
    }
}

@media (--viewport-tablet) {
    .s-form {
        &__wrapper {
            margin: 0 auto;
        }

        &-sem-ebook {
            .s-form {
                &__wrapper {
                    padding: 0;
                    display: flex;
                    max-width: none;
                    justify-content: space-between;
                }
            }
        }
    }
}

@media (--viewport-desktop) {
    .s-form {
        &-single {
            padding: 128px 0 64px;
        }

        &__wrapper {
            display: flex;
            max-width: 100%;
            flex-wrap: nowrap;
            justify-content: space-between;
        }
    }
}

.s-form__wrapper-blocks {
    gap: 32px;
    display: flex;
    flex-wrap: wrap;
    max-width: 100%;
    align-content: flex-start;

    @media (--viewport-tablet) {
        gap: 16px;
        flex-wrap: nowrap;
    }

    @media (--viewport-desktop-wide) {
        gap: 53px;
        flex-wrap: nowrap;
    }
}
</style>
